
import { AdminClient, GameClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class AppConfiguration extends Vue {

    isGalleryPublished: boolean = false;
    isChallengePublished: boolean = false;
    isAppPublished: boolean = false;

    created() {
        Promise.all([
            AdminClient.getGalleryStatus(),
            AdminClient.getChallengeStatus(),
            AdminClient.getAppStatus()
        ])
        .then(xs => {
            this.isGalleryPublished = xs[0];
            this.isChallengePublished = xs[1];
            this.isAppPublished = xs[2];
        })
    }

    toggleGalleryConfiguration() {
        AdminClient.toggleGalleryPublished()
        .then(x => {
            this.isGalleryPublished = !this.isGalleryPublished;
        })
    }
    toggleChallengeConfiguration() {
        AdminClient.toggleChallengePublished()
        .then(x => {
            this.isChallengePublished = !this.isChallengePublished;
        })
    }
    toggleAppConfiguration() {
        AdminClient.toggleAppPublished()
        .then(x => {
            this.isAppPublished = !this.isAppPublished;
        })
    }

}
